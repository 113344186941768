/* Reset */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  object-fit: contain;
  color: transparent;/* Hiding alt texts of images from showing */
}


/* Font */

@font-face {
  font-family: 'Tinos';
  src: local('Tinos'), url(./Tinos-Regular.ttf) format('truetype');
}

body,
html,
#root,
.App {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 10;
}

.App {
  font-family: "Tinos", sans-serif;
  font-style: normal;
  text-align: center;
  color: white;
  position: relative;
  /* This element uses viewheight, all others must use percentage to calculate properly */
  height: 100vh;
  height: 100dvh;
  min-height: 300px;
  width: 100vw;
  overflow: hidden;
}

h1 {
  font-size: 60px;
  margin: 6px;
  margin-bottom: 20px;
  font-weight: normal;
}

h2 {
  font-size: 39px;
  margin: 6px;
  font-weight: normal;
}

h3 {
  font-size: 25px;
  margin-top: 13px;
  margin-bottom: 13px;
  font-weight: normal;
}

h4 {
  font-size: 18px;
  margin-top: 13px;
  margin-bottom: 13px;
  font-weight: normal;
}

p {
  font-size: 18px;
  margin-top: 13px;
  margin-bottom: 13px;
  font-weight: normal;
}

a {
  color: white;
  cursor: pointer;
}

input[type="text"],
textarea {
  background-color: white;
  border-radius: 9px;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 19px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5;
}

textarea.unfocused:focus {
  border: none;
  outline: none;
}

button {
  font-family: "Tinos", serif;
  font-style: normal;
  font-size: 25px;
  font-weight: normal;
  color: white;
  background-color: transparent;
  border: 1.5px solid white;
  border-radius: 19px;
  padding: 0px 16px 0px 16px;
  cursor: pointer;
}

button:hover:not(.control) {
  background-color: white;
  color: rgba(0, 0, 0, 0.9);
}

button.selected {
  background-color: white;
  color: rgba(0, 0, 0, 0.9);
}

/* We have to do this as a pseudo element to enable nested blurring in chrome */
/* https://stackoverflow.com/questions/60997948/backdrop-filter-not-working-for-nested-elements-in-chrome */
.blur::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: backdrop-filter 0.2s;
  backdrop-filter: blur(10px) opacity(1);
  -webkit-backdrop-filter: blur(10px) opacity(1);
  z-index: -1;
}

/* Soft edge fade on menu */
nav.blur::before {
  /* background: red; */
  transition: backdrop-filter 1s;
  mask: linear-gradient(to top, transparent, black 20%);
}

.blur.hide::before {
  backdrop-filter: blur(10px) opacity(0);
  -webkit-backdrop-filter: blur(10px) opacity(0);
}



#protocol-container p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
}

#protocol-container ol,
#protocol-container ul {
  margin: 0;
  white-space: normal;
  font-size: 18px;
}

#protocol-container li {
  margin-bottom: 5px;
}
#printed-style{
  white-space: pre-wrap;
  letter-spacing: normal;
}

#printed-style strong{  
  letter-spacing: 0.2px;
  word-spacing: 0.5px;
}

#printed-style p {
  font-size: 10px;
  margin-top: 1px;
  /* this fixes the line numbering positioning problem, not sure why */
  margin-bottom: 1px;
}

#printed-style ol,
#printed-style ul {
  font-size: 10px;
  margin: 0;
  white-space: normal;
  padding-left: 1.2em;
}


#printed-style li {
  padding-top: 2px;
  /* this fixes the line numbering positioning problem, not sure why */
  font-size: 10px;
  margin-bottom: 5px;
}

#printed-style strong {
  font-weight: bold;
}

#printed-style hr {
  margin: 0;
}

#printed-style h3 {
  font-size: 20px;
  margin: 0;
}

#printed-style a {
  color: #000;
  text-decoration: underline;
}

@keyframes slideInFade {
  0% {
    transform: translateX(20%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes micAppearing {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes foodAppearing {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.loader {
  width: 15px;
  height: 3px;
  margin-top: 13px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #fff 90%, #0000) 0 / calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 2s steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}

/* Scrollbar fixes */
.scroll {
  scrollbar-width: thin;
  scrollbar-color: #222 transparent;
}

::-webkit-scrollbar {
  width: 11px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #222;
  border-radius: 6px;
  border: none;
}

@media only screen and (max-height: 600px) {
  body {
    -webkit-text-size-adjust: 100%;
  }

  h1 {
    font-size: 38px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 21px;
  }

  h4 {
    font-size: 15px;
  }

  #protocol-container p,
  #protocol-container ol,
  #protocol-container ul,
  p {
    font-size: 16px;
    line-height: 1.1em;
  }

  button {
    font-size: 19px;
  }

  .loader {
    width: 15px;
    height: 2.5px;
    margin-top: 11px;
  }
}

/* Ultra small */
@media only screen and (max-height: 370px) {
  body {
    -webkit-text-size-adjust: 100%;
  }

  h1 {
    font-size: 31px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 15px;
  }

  h3,p{
    margin-top: 5px;
    margin-bottom: 5px;
  }

  #protocol-container p,
  #protocol-container ol,
  #protocol-container ul,
  p {
    font-size: 15px;
    line-height: 1.0em; 
  }

  button {
    font-size: 17px;
  }

  .loader {
    width: 15px;
    height: 2.5px;
    margin-top: 11px;
  }
}

@media only screen and (orientation: portrait) and (max-width: 600px) {
  h1 {
    font-size: 38px;
  }

  h2 {
    font-size: 26px;
  }

  p {
    font-size: 16px;
    line-height: 1.1em;
  }

}